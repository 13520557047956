.loader_spinner {
  margin: 0 auto;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader_spinner2 {
  margin-top: -30px !important;
  margin: 0 auto;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}
.btn_loader {
  margin: 0 auto;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  animation: spin 1s linear infinite;
}
.main_wrapper {
  margin-top: -40px;
}

.custom_modal_backdrop {
  /* background-color:red;  */
  box-shadow: rgba(156, 152, 152, 0.001) !important;
}
.data_grid_table {
  min-height: 600px !important;
  border: 1px solid black;
}
